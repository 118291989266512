<template>
  <div class="extended-billable-wrapper">
    <div v-if="isLoading" class="extended-billable__spinner-wrapper">
      <b-spinner class="extended-billable__loader" type="grow" small />
    </div>
    <div v-else class="extended-billable">
      <div class="extended-billable__header">
        <img
          :src="`/images/document_32.svg`"
          alt="Document icon"
          class="extended-billable__icon"
        />
        <div class="extended-billable__title">{{ offerName }}</div>
        <div class="extended-billable__price-section">
          <div class="extended-billable__price">
            {{ formattedSupplierCost }}
          </div>
          <div class="extended-billable__subtitle">
            {{ supplierCostSubtitle }}
          </div>
        </div>
      </div>

      <div class="extended-billable__body-section px-5">
        <div class="extended-billable__action-buttons">
          <button
            v-for="button in actionButtons"
            :key="button.id"
            class="extended-billable__btn"
            @click="button.action"
          >
            {{ button.label }}
          </button>
        </div>

        <div class="extended-billable__summary-section">
          <div
            v-for="item in summaryItems"
            :key="item.id"
            class="extended-billable__subsection"
          >
            <div class="extended-billable__label">
              {{ item.label }}
            </div>
            <div class="extended-billable__value">
              {{ item.value }}
            </div>
          </div>
        </div>

        <div class="extended-billable__cost-summary">
          <billable-price-summary
            v-if="showBillablePriceSummary"
            :title="costSummaryTitle"
            :currency="currency"
            :billable-price-summary="billablePriceSummary"
            :frequency-options="frequencyOptions"
          />
        </div>

        <div class="extended-billable__attachments">
          <div class="extended-billable__label">
            {{ attachmentTitle }}
          </div>
          <div class="extended-billable__attachment">
            <attachments :attachments="attachments" :tender="tender" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tenderService from "@/services/tenderService";
import { RouteNames } from "@/router/routes";
import cmsService from "@/services/cmsService";
import { getFileUrl } from "@/utilities/cmsUtilities";
import Attachments from "@/components/Attachments/Attachments.vue";
import BillablePriceSummary from "@/components/BillablePriceSummary/BillablePriceSummary.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { BillingCycleUnits, OfferStatus } from "@/custom-types/types";
import { convertDateToLocaleTimeStringDotSeparated } from "@/utilities/dateUtilities";
import useFileDownload from "@/hooks/useFileDownload.ts";
import { useFileAttachmentApi } from "@/services/api/useFileAttachmentApi";
import { useTenderApi } from "@/services/api/useTenderApi";

const { viewBlobFile } = useFileDownload();
const { getFrameAgreement } = useTenderApi();

export default {
  components: {
    Attachments,
    BillablePriceSummary,
  },
  props: {
    tender: {
      type: Object,
      default: () => {},
    },
    billablePriceSummary: {
      type: Array,
      default: () => [],
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    serviceOffers: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: "Serviceavtale nød- og ledelys",
      price: "34 875 kr",
      priceSubtitle: "Gjennomsnittlig kostnad",
      actionButtons: [
        {
          id: 1,
          label: "SE DETALJER",
          action: this.viewDetails,
        },
        {
          id: 2,
          label: "LAST NED AVTALE",
          action: this.handleSeeAgreementClick,
        },
        {
          id: 3,
          label: "LAST NED VILKÅR",
          action: this.handleDownloadTermsClick,
        },
      ],
      costSummaryTitle: "Faste kostnader (ekskl. mva)",
      currency: "kr",
      attachmentTitle: "Vedlegg til avtale",
      termsAndConditionsCms: null,
      frameAgreement: null,
      isLoadingFrameAgreement: true,
    };
  },
  computed: {
    serviceTypesCms() {
      return this.$store.state.cms.serviceTypes;
    },

    showBillablePriceSummary() {
      return this.billablePriceSummary.length > 0;
    },

    offerName() {
      const defaultName = "Tilbud på tjenestetype";
      const serviceOfferExtra = this.serviceOffers[0].serviceOfferExtra;
      return serviceOfferExtra?.nickname
        ? serviceOfferExtra?.nickname
        : defaultName;
    },

    formattedSupplierCost() {
      const billingCycle =
        BillingCycleUnits[this.serviceOffers[0]?.billingCycle];
      return `${formatCurrency(
        this.serviceOffers[0]?.supplierCost,
      )} ${billingCycle ?? ""} ekskl. mva`;
    },

    supplierCostSubtitle() {
      const billingCycle =
        BillingCycleUnits[this.serviceOffers[0]?.billingCycle];
      return `Gjennomsnittlig kostnad per ${billingCycle ?? ""} ekskl. mva`;
    },

    signedOfferDate() {
      const offerStateChange = this.findOfferStateChange;
      const createdOn = offerStateChange?.createdOn;
      const signedOfferDate = this.formatModifiedDate(createdOn);

      return signedOfferDate ?? "";
    },

    findOfferStateChange() {
      if (!this.serviceOffers[0]) {
        return null;
      }
      return this.serviceOffers[0].offerStateChanges.find(
        (offerStateChange) => offerStateChange.stateTo === OfferStatus.Accepted,
      );
    },

    getWantedStartDate() {
      if (!this.tender) return "";

      const priceRequest = this.tender.priceRequests[0];

      const wantedStartDate = priceRequest?.wantedStartDate;
      const supplierRequest = priceRequest?.supplierRequests?.[0];
      const supplierRequestAcceptanceStartDate =
        supplierRequest?.supplierRequestAcceptances?.[0]?.startDate;
      return wantedStartDate ?? supplierRequestAcceptanceStartDate ?? "";
    },

    wantedStartDate() {
      return this.formatDate(this.getWantedStartDate) ?? "";
    },

    serviceCategoryDetailsTitle() {
      const serviceCategoryDetails = this.getServiceCategoryDetails(
        this.tender?.priceRequests?.[0],
      );
      return serviceCategoryDetails?.title ?? "";
    },

    summaryItems() {
      return [
        {
          id: 1,
          label: "Tjenestepakke",
          value: this.serviceCategoryDetailsTitle,
        },
        {
          id: 2,
          label: "Oppstartsdato",
          value: this.wantedStartDate,
        },
        {
          id: 3,
          label: "Signert",
          value: this.signedOfferDate,
        },
      ];
    },

    attachments() {
      return this.serviceOffers[0]?.serviceOfferExtra?.attachments || [];
    },

    priceRequest() {
      return this.tender.priceRequests?.[0];
    },

    supplierRequest() {
      return this.priceRequest?.supplierRequests?.[0];
    },

    customerOrganizationId() {
      return this.tender?.customerOrganizationId;
    },

    hasFrameAgreement() {
      return this.supplierRequest?.hasFrameAgreement ?? false;
    },

    frameAgreementId() {
      return this.supplierRequest?.frameAgreementId || null;
    },
  },

  async created() {
    await this.fetchTermsAndConditionsCms();
  },

  async mounted() {
    await this.fetchFrameAgreement();
  },

  methods: {
    async fetchFrameAgreement() {
      if (
        this.hasFrameAgreement &&
        this.frameAgreementId &&
        this.frameAgreementId > 0 &&
        this.customerOrganizationId
      ) {
        this.isLoadingFrameAgreement = true;
        try {
          const response = await getFrameAgreement({
            organizationId: this.customerOrganizationId,
            frameAgreementId: this.frameAgreementId,
          });
          this.frameAgreement = response.data || null;
        } catch (err) {
          console.error("Error fetching frame agreement:", err);
          this.frameAgreement = null;
        } finally {
          this.isLoadingFrameAgreement = false;
        }
      } else {
        this.isLoadingFrameAgreement = false;
      }
    },

    viewDetails() {
      this.redirectToEditOffer(this.serviceOffers[0].id);
    },

    redirectToEditOffer(offerId) {
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        params: {
          tenderId: this.tender.tenderId,
          offerId: offerId,
        },
      });
    },

    async handleSeeAgreementClick() {
      const serviceOfferId = this.serviceOffers[0].id;
      if (!serviceOfferId) return;
      await viewBlobFile(async () => {
        const response = await tenderService.getTenderConfirmationUri(
          this.tender.tenderId,
          serviceOfferId,
          "Supplier",
        );
        return response.data;
      }, this.$router);
    },

    async fetchTermsAndConditionsCms() {
      const response = await cmsService.getTermsAndConditionsCms();
      if (response && response.length) {
        this.termsAndConditionsCms = response[0];
      }
    },

    async handleDownloadTermsClick() {
      try {
        if (this.hasFrameAgreement && this.frameAgreement) {
          // If there's a frame agreement, download its attachment
          await this.downloadFrameAgreementAttachment();
        } else {
          this.openTermsAndConditions();
        }
      } catch (error) {
        console.error("Error downloading terms:", error);
      }
    },

    async downloadFrameAgreementAttachment() {
      const { viewFileInNewWindow } = useFileDownload();
      const { getAttachment } = useFileAttachmentApi();

      const attachment = this.frameAgreement.attachments[0]; // Assuming the first attachment is the one we want
      if (!attachment) {
        throw new Error("No frame agreement attachment found");
      }

      const response = await getAttachment({
        attachmentId: attachment.attachmentId,
        tenderId: this.tender.tenderId,
        fileName: attachment.fileName,
      });

      await viewFileInNewWindow(response.data, this.$router);
    },

    openTermsAndConditions() {
      const url = getFileUrl(
        this.termsAndConditionsCms?.filedoc,
        "seeAllTerms",
      );

      if (!url) {
        throw new Error("Terms and conditions URL not found");
      }

      window.open(url, "_blank", "noopener,noreferrer");
    },

    formatModifiedDate(dateString) {
      if (!dateString) return "";

      const [year, month, day] = dateString.split("T")[0].split("-");
      const shortenedYear = year.slice(-2);
      return `${day}.${month}.${shortenedYear}`;
    },

    getSelectedServiceTypePackagePortfolio() {
      if (!this.tender.supplierPortfolios) return;

      const serviceTypePackagePortfolios =
        this.tender.supplierPortfolios.flatMap(
          (supplierPortfolio) => supplierPortfolio.serviceTypePackagePortfolio,
        );

      return serviceTypePackagePortfolios.find(
        this.isServiceTypePackagePortfolioSelected,
      );
    },

    isServiceTypePackagePortfolioSelected(serviceTypePackagePortfolio) {
      return (
        serviceTypePackagePortfolio.offerStatus === OfferStatus.Accepted ||
        serviceTypePackagePortfolio.offerStatus === OfferStatus.Completed
      );
    },

    formatDate(date) {
      if (!date) return "";
      return convertDateToLocaleTimeStringDotSeparated(date) ?? "";
    },

    getServiceCategoryDetails(priceRequest) {
      if (!priceRequest) {
        return {};
      }
      const serviceType = this.serviceTypesCms.find(
        (type) => type.label === priceRequest.serviceTypeLabel,
      );

      return (
        serviceType?.serviceCategories.find(
          (subcategory) =>
            subcategory.label === priceRequest.serviceCategoryLabel,
        ) || {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$base-padding: 20px;
$base-border: 1px solid #000;
$base-font-color: #333;
$sub-font-color: #666;
$highlight-color: rgba(0, 0, 0, 0.05);
$active-color: rgba(0, 0, 0, 0.1);

.extended-billable-wrapper {
  background: $color-primary-pink;
  background-color: $color-primary-pink;
  border-radius: 6px;
  width: 100%;
  z-index: 0;
  min-height: 600px;
}

.extended-billable {
  padding: 24px 24px 40px 24px;
  border-radius: 6px;
  width: 100%;
  z-index: 0;

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
    margin: auto;
  }

  &__header,
  &__action-buttons,
  &__summary-section,
  &__attachments {
    margin-bottom: $base-padding;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: $font-size-base;
    color: #000;
    line-height: normal;
    text-align: left;
    margin-left: 16px;
  }

  &__price-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
  }

  &__price {
    font-size: $font-size-base;
    font-weight: 700;
    color: #000;
  }

  &__subtitle {
    font-size: $font-size-sm;
    color: #787878;
    font-weight: 400;
  }

  &__action-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  &__btn {
    padding: 12px 16px;
    font-size: $font-size-xs;
    font-weight: 600;
    border: $base-border;
    border-radius: 4px;
    cursor: pointer;
    background-color: inherit;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $color-primary;
      color: #fff;
      border: none;
    }

    &:active {
      background-color: $active-color;
    }
  }

  &__summary-section {
    display: flex;
    flex-wrap: wrap;
  }

  &__subsection {
    width: 50%;
    text-align: left;
    margin-top: 32px;

    &:nth-child(odd) {
      padding-right: 8px;
    }

    &:nth-child(even) {
      padding-left: 8px;
    }
  }

  &__label {
    padding-bottom: 4px;
    border-bottom: 1px solid #868686;
    font-size: $font-size-xs;
    font-weight: 400;
    color: #1d1d1d;
    text-transform: uppercase;
    text-align: left;
  }

  &__value {
    margin-top: 4px;
    font-size: $font-size-sm;
    font-weight: 600;
    color: #1d1d1d;
  }

  &__attachments {
    padding-top: 32px;
  }
}
</style>
